import LoginForm from './LoginForm';

function App() {
  return (
    <div className="App">
      <LoginForm></LoginForm>
    </div>
  );
}

export default App;

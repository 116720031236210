import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';

export default function LoginForm() {
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values: any) => {
    const reqData = {
      username: values.username,
      password: values.password,
    };
    let errMsg = '登录失败';
    try {
      const resp = await axios.create({withCredentials: true}).post('https://api.dashboard.poxiao.me/login', reqData);
      if (resp.data.errorCode === 0) {
        window.location.href = 'https://dashboard.poxiao.me';
        return;
      }
      return;
    } catch (e) {
    }
    messageApi.error(errMsg)
  };

  return (
    <div className="flex justify-center items-center h-screen">
      { contextHolder }
      <Form
        name="normal_login"
        className="w-64"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
